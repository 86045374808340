var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.tableHeaders,"items":_vm.contents,"hide-default-footer":"","loading":_vm.loading},scopedSlots:_vm._u([{key:"item.month",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getDateFormat(item.month))+" ")]}},{key:"item.sales",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.numberFormat(item.sales))+" ")]}},{key:"item.grossPms",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.numberFormat(item.grossPms))+" ")]}},{key:"item.more",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex justify-end align-center"},[_c('v-btn',{staticClass:"mx-1",attrs:{"color":"grey lighten-2","elevation":"0","small":""},on:{"click":function($event){return _vm.showPMS(item)}}},[_vm._v(" รายละเอียด ")])],1)]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }