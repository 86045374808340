<template>
  <div>
    <v-tabs
      v-model="selectedTab"
      fixed-tabs
      color="black"
    >
      <v-tab
        v-for="tab in tabs"
        :key="tab">
        {{ tab }}
      </v-tab>
    </v-tabs>

    <v-tabs-items v-model="selectedTab">
      <v-tab-item>
        <sales-of-month
          v-if="checkTab(0)"
          :employee="employee"
          @show-pms="showPMS($event)" />
      </v-tab-item>

      <v-tab-item>
        <complain
          v-if="checkTab(1)"
          :employee="employee" />
      </v-tab-item>

      <v-tab-item>
        <day-off
          v-if="checkTab(2)"
          :employee="employee" />
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>

<script>
import Complain from './tabs/Complain.vue'
import DayOff from './tabs/DayOff.vue'
import SalesOfMonth from './tabs/SalesOfMonth.vue'

export default {
  components: {
    SalesOfMonth,
    Complain,
    DayOff
  },
  props: {
    employee: {
      type: Object,
      default: () => {}
    }
  },
  data () {
    return {
      selectedTab: null,
      tabs: ['ยอดขาย', 'รายการคำชม/คอมเพลน', 'วันลา/ทำงาน']
    }
  },
  methods: {
    checkTab (index) {
      return this.selectedTab === index
    },
    showPMS (detailReports) {
      this.$emit('show-pms', detailReports)
    }
  }
}
</script>

<style scoped>
</style>
