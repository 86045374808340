<template>
  <v-row justify="end">
    <v-dialog
      v-model="dialog"
      persistent
      max-width="600px">
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          class="mx-1 my-1"
          color="grey lighten-2"
          elevation="0"
          small
          v-bind="attrs"
          v-on="on"
          @click="initForm()">
          แก้ไข
        </v-btn>
      </template>
      <v-card>
        <v-card-title>
          <span class="text-h5">แก้ไขวันลา / ทำงาน</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12">
                <v-menu
                  ref="datePicker"
                  v-model="datePicker"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  max-width="290px"
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="dateFormatted"
                      label="วันที่"
                      prepend-icon="mdi-calendar"
                      dense
                      outlined
                      hide-details
                      v-bind="attrs"
                      @blur="date = parseDate(dateFormatted)"
                      v-on="on" />
                  </template>
                  <v-date-picker
                    v-model="date"
                    readonly
                    no-title
                    @input="datePicker = false"
                  ></v-date-picker>
                </v-menu>
              </v-col>
            </v-row>
            <v-row
              v-for="(warehouse, index) in dayoffForm.warehouses"
              :key="index">
              <v-col cols="10">
                <v-select
                  v-model="warehouse.warehouse.name"
                  :items="mappedWarehouses"
                  label="สาขา"
                  hide-details
                  outlined
                  dense />
              </v-col>
              <v-col cols="2">
                <v-btn
                  color="error"
                  @click="deleteWarehouse(warehouse, index)">
                  <v-icon>mdi-delete</v-icon>
                </v-btn>
              </v-col>
              <v-col
                cols="12"
                sm="4"
                md="6">
                <v-text-field
                  v-model="warehouse.day.off"
                  type="number"
                  label="วันลา"
                  dense
                  outlined
                  hide-details
                  required />
              </v-col>
              <v-col
                cols="12"
                sm="4"
                md="6">
                <v-text-field
                  v-model="warehouse.day.work"
                  type="number"
                  label="วันทำงาน"
                  dense
                  outlined
                  hide-details
                  required />
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-btn
            color="black"
            text
            @click="addWarehouse()">
            <v-icon small>
              mdi-plus
            </v-icon>
            สาขา
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            color="blue darken-1"
            text
            @click="closeForm()">
            ปิด
          </v-btn>
          <v-btn
            color="blue darken-1"
            text
            @click="saveForm()">
            บันทึก
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  props: {
    reports: {
      type: Object,
      default: () => {}
    },
    fetchWarehouses: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {
      date: null,
      dateFormatted: null,
      datePicker: false,
      dialog: false,
      dayoffForm: {
        month: {
          month: null,
          year: null
        },
        warehouses: []
      },
      mappedWarehouses: [],
      deleteWarehouses: []
    }
  },
  watch: {
    date (val) {
      this.dateFormatted = this.formatDate(val)
    }
  },
  methods: {
    ...mapActions({
      setSnackbar: 'Components/setSnackbar'
    }),
    initForm () {
      this.initDate()

      this.mappedWarehouses = this.fetchWarehouses
        .map((warehouse) => warehouse.name)
        .sort((a, b) => a.localeCompare(b))

      this.dayoffForm.warehouses = this.reports.reports.map((w) => ({
        id: w.id,
        day: {
          off: w.day.off,
          work: w.day.work
        },
        warehouse: {
          id: w.warehouse.id,
          code: w.warehouse.code,
          name: w.warehouse.name
        }
      }))

      if (!this.dayoffForm.warehouses.length) {
        this.addWarehouse()
      }
    },
    initDate () {
      this.date = this.$dayjs(this.reports.reportDate).format('YYYY-MM-DD')
      this.dateFormatted = this.$dayjs().format('YYYY-MM-DD')
    },
    formatDate (date) {
      if (!date) return null

      return this.$dayjs(date).format('MM/DD/YYYY')
    },
    parseDate (date) {
      if (!date) return null

      return this.$dayjs(date).format('YYYY-MM-DD')
    },
    addWarehouse () {
      this.dayoffForm.warehouses.push({
        id: null,
        day: {
          off: '',
          work: ''
        },
        warehouse: {
          id: '',
          code: '',
          name: ''
        }
      })
    },
    async deleteWarehouse (warehouse, index) {
      if (warehouse.id) {
        this.deleteWarehouses.push(warehouse.id)
      }

      this.dayoffForm.warehouses.splice(index, 1)
    },
    saveForm () {
      let isDone = true

      this.dayoffForm.warehouses.forEach((w) => {
        if (w.day.off === '' || w.day.work === '' || w.warehouse.name === '') {
          isDone = false
        }
      })

      if (!isDone) {
        this.setSnackbar({
          value: true,
          message: 'Please fill information all box.',
          type: 'error'
        })

        return
      }

      const month = this.$dayjs(this.date).format('M')
      const year = this.$dayjs(this.date).format('YYYY')
      this.dayoffForm.month = {
        month: (+month),
        year: (+year)
      }
      this.dayoffForm.warehouses = this.dayoffForm.warehouses.map((warehouse) => {
        const tmpWarehouse = warehouse
        tmpWarehouse.day = {
          off: (+tmpWarehouse.day.off),
          work: (+tmpWarehouse.day.work)
        }
        tmpWarehouse.warehouse = this.fetchWarehouses.find((w) => w.name === tmpWarehouse.warehouse.name)

        return tmpWarehouse
      })

      this.$emit('save-form', { save: this.dayoffForm, delete: this.deleteWarehouses })
      this.dialog = false
    },
    closeForm () {
      this.dialog = false
    }
  }
}
</script>
