<template>
  <v-row justify="center">
    <v-dialog
      v-model="dialog"
      persistent
      max-width="600px">
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          class="mx-1"
          color="grey lighten-2"
          elevation="0"
          v-bind="attrs"
          v-on="on">
          แก้ไข
        </v-btn>
      </template>
      <v-card>
        <v-card-title>
          <span class="text-h5">User Profile</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col
                cols="12"
                sm="6"
                md="6">
                <v-text-field
                  v-model="employeeForm.firstName"
                  label="First name"
                  dense
                  outlined
                  hide-details
                  required />
              </v-col>
              <v-col
                cols="12"
                sm="6"
                md="6">
                <v-text-field
                  v-model="employeeForm.lastName"
                  label="Last name"
                  dense
                  outlined
                  hide-details
                  required />
              </v-col>
              <v-col cols="12">
                <v-text-field
                  v-model="employeeForm.tel"
                  label="Telephone No."
                  dense
                  outlined
                  hide-details
                  required />
              </v-col>
              <v-col cols="12">
                <v-menu
                  ref="datePicker"
                  v-model="datePicker"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  max-width="290px"
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="dateFormatted"
                      label="Date"
                      prepend-icon="mdi-calendar"
                      dense
                      outlined
                      hide-details
                      v-bind="attrs"
                      @blur="date = parseDate(dateFormatted)"
                      v-on="on" />
                  </template>
                  <v-date-picker
                    v-model="date"
                    no-title
                    @input="datePicker = false"
                  ></v-date-picker>
                </v-menu>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <v-card-title>
          <span class="text-h5">Emergency Contact</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12">
                <v-text-field
                  v-model="employeeForm.emergencyContact.name"
                  label="Name"
                  dense
                  outlined
                  hide-details
                  required />
              </v-col>
              <v-col cols="12">
                <v-text-field
                  v-model="employeeForm.emergencyContact.tel"
                  label="Telephone No."
                  dense
                  outlined
                  hide-details
                  required />
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <v-card-title>
          <span class="text-h5">Status</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12">
                <v-select
                  v-model="employeeForm.role"
                  hide-details
                  label="Role"
                  outlined
                  dense
                  color="secondary"
                  :items="roles"
                  item-text="text"
                  item-value="value"
                  required>
                </v-select>
              </v-col>
              <v-col cols="12">
                <v-select
                  v-model="employeeForm.status"
                  hide-details
                  label="Status"
                  outlined
                  dense
                  color="secondary"
                  :items="status"
                  item-text="text"
                  item-value="value"
                  required>
                </v-select>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue darken-1"
            text
            @click="closeForm()">
            Close
          </v-btn>
          <v-btn
            color="blue darken-1"
            text
            @click="saveForm()">
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
  import EmployeeRoles from '@/assets/json/EmployeeRoles.json'

  export default {
    props: {
      employee: {
        type: Object,
        default: () => {}
      }
    },
    data () {
      return {
        date: null,
        dateFormatted: null,
        datePicker: false,
        dialog: false,
        roles: [
          {
            text: 'Sales Staff',
            value: EmployeeRoles.SALES_STAFF
          },
          {
            text: 'Assist Store Manager',
            value: EmployeeRoles.ASSIST_STORE_MANAGER
          },
          {
            text: 'Store Manager',
            value: EmployeeRoles.STORE_MANAGER
          },
          {
            text: 'Area Manager',
            value: EmployeeRoles.AREA_MANAGER
          }
        ],
        status: [
          {
            text: 'Active',
            value: 'active'
          },
          {
            text: 'Resign',
            value: 'resign'
          }
        ]
      }
    },
    computed: {
      employeeForm () {
        return JSON.parse(JSON.stringify(this.employee))
      }
    },
    watch: {
      date (val) {
        this.dateFormatted = this.formatDate(val)
      }
    },
    created () {
      this.initDate()
    },
    methods: {
      initDate () {
        this.date = this.$dayjs(this.employeeForm.startWorkDate).format('YYYY-MM-DD')
        this.dateFormatted = this.$dayjs().format('YYYY-MM-DD')
      },
      formatDate (date) {
        if (!date) return null

        return this.$dayjs(date).format('MM/DD/YYYY')
      },
      parseDate (date) {
        if (!date) return null

        return this.$dayjs(date).format('YYYY-MM-DD')
      },
      saveForm () {
        this.employeeForm.startWorkDate = this.$dayjs(this.date).toISOString()
        this.$emit('save-form', this.employeeForm)
        this.dialog = false
      },
      closeForm () {
        this.dialog = false
      }
    }
  }
</script>
