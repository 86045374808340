<template>
  <div class="pms-performance-invidual-container">
    <div class="pms-performance-individual-content mb-3">
      <div class="name">
        <strong>Cashier name</strong> : {{ `${employee.firstName} ${employee.lastName}` }}
      </div>

      <v-expansion-panels
        v-model="panel"
        class="mb-3"
        focusable>
        <v-expansion-panel
          v-for="(report, index) in reports"
          :key="index">
          <v-expansion-panel-header>
            {{ getDateFormat(report.month) }}
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-row class="mt-2">
              <v-col class="label">
                Sales
              </v-col>
              <v-col>
                {{ numberFormat(report.sales) }} THB
              </v-col>
            </v-row>
            <v-row>
              <v-col class="label">
                Days
              </v-col>
              <v-col>
                {{ report.workDay.workedDays }}
              </v-col>
            </v-row>
            <v-row>
              <v-col class="label">
                Off
              </v-col>
              <v-col>
                {{ report.workDay.offWorks }}
              </v-col>
            </v-row>
            <v-row>
              <v-col class="label">
                Per day
              </v-col>
              <v-col>
                {{ numberFormat(report.salePerDay) }} THB
              </v-col>
            </v-row>
            <v-row>
              <v-col class="label">
                Day %
              </v-col>
              <v-col>
                {{ numberFormat(report.day) }}%
              </v-col>
            </v-row>
            <v-row>
              <v-col class="label">
                Bonus
              </v-col>
              <v-col>
                {{ numberFormat(report.bonus) }} THB
              </v-col>
            </v-row>
            <v-row>
              <v-col class="label">
                Gross PMS
              </v-col>
              <v-col>
                {{ numberFormat(report.grossPms) }} THB
              </v-col>
            </v-row>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>

      <v-btn
        color="grey lighten-2"
        elevation="0"
        small
        @click="back()">
        ย้อนกลับ
      </v-btn>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    employee: {
      type: Object,
      default: () => {}
    },
    reports: {
      type: Array,
      default: () => []
    },
    reportDetail: {
      type: Object,
      default: () => {}
    }
  },
  data () {
    return {
      panel: 1
    }
  },
  created () {
    const index = this.reports.findIndex((report) => report.month.month === this.reportDetail.month.month)
    this.panel = index
  },
  methods: {
    getDateFormat (month) {
      const tmpMonth = this.$dayjs().month(month.month - 1).format('MMMM')
      return `${tmpMonth} ${month.year}`
    },
    numberFormat (number) {
      if (!number) {
        return Number(0).toLocaleString('en')
      }
      const tmpNumber = number.toFixed(2)
      return Number(tmpNumber).toLocaleString('en')
    },
    back () {
      this.$emit('back')
    }
  }
}
</script>

<style scoped>
.pms-performance-individual-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: fit-content;
}
.pms-performance-individual-content {
  display: flex;
  flex-direction: column;
  width: 90%;
  height: fit-content;
  margin: 0 auto;
}
.pms-performance-individual-content > .name {
  background-color: #e9e9e9;
  border-radius: 10px;

  padding: 10px;
  margin: 10px 0;
  width: 100%;
  height: 40px;
}
.pms-performance-individual-content > button {
  width: 20%;
  max-width: 100px;
  margin: 0 auto;
}
</style>
