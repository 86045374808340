<template>
  <div>
    <v-card>
      <v-data-table
        :headers="tableHeaders"
        :items="contents"
        :loading="loading"
        hide-default-footer
        class="elevation-1">
        <template #[`item.warehouse`]="{ item }">
          <div
            v-for="(warehouse, index) in item.warehouse"
            :key="index">
            {{ warehouse }}
          </div>
        </template>
        <template #[`item.dayoff`]="{ item }">
          <div
            v-for="(dayoff, index) in item.dayoff"
            :key="index">
            {{ dayoff }}
          </div>
        </template>
        <template #[`item.daywork`]="{ item }">
          <div
            v-for="(daywork, index) in item.daywork"
            :key="index">
            {{ daywork }}
          </div>
        </template>
        <template #[`item.edit`]="{ item }">
          <edit-day-off-modal
            :reports="item"
            :fetch-warehouses="fetchWarehouses"
            @save-form="saveForm($event)" />
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import WorkDayProvider from '@/resources/WorkDayProvider'
import ReviewWarehousesProvider from '@/resources/ReviewWarehouses'
import EditDayOffModal from './EditDayOffModal.vue'

const WorkDayService = new WorkDayProvider()
const ReviewWarehousesService = new ReviewWarehousesProvider()

export default {
  components: { EditDayOffModal },
  props: {
    employee: {
      type: Object,
      default: () => {}
    }
  },
  data () {
    return {
      tableHeaders: [
        {
          text: 'เดือน',
          sortable: true,
          value: 'reportDate'
        },
        {
          text: 'สาขา',
          align: 'left',
          sortable: false,
          value: 'warehouse'
        },
        {
          text: 'วันลา',
          align: 'center',
          sortable: false,
          value: 'dayoff'
        },
        {
          text: 'วันทำงาน',
          align: 'center',
          sortable: false,
          value: 'daywork'
        },
        {
          text: '',
          align: 'center',
          sortable: false,
          value: 'edit'
        }
      ],
      fetchReports: [],
      fetchWarehouses: [],
      loading: false
    }
  },
  computed: {
    contents () {
      return this.fetchReports
    }
  },
  created () {
    this.initWorkDay()
  },
  methods: {
    ...mapActions({
      setSnackbar: 'Components/setSnackbar'
    }),
    async initWorkDay () {
      try {
        this.loading = true

        const end = this.$dayjs()
        const start = end.subtract(5, 'month')
        const query = {
          start: start.format('M-YYYY'),
          end: end.format('M-YYYY'),
          userId: this.employee.userId
        }

        const { data } = await WorkDayService.getWorkDayGroupByMonth(query)
        const { data: warehouses } = await ReviewWarehousesService.getReviewWarehouses()

        const mapped = data.map((monthReport) => {
          const reportDate = this.getDateFormat(monthReport.month)
          const warehouse = monthReport.reports.map((report) => report.warehouse.name)
          const dayoff = monthReport.reports.map((report) => report.day.off)
          const daywork = monthReport.reports.map((report) => report.day.work)

          return {
            reportDate,
            reports: monthReport.reports,
            warehouse,
            dayoff,
            daywork
          }
        })

        this.fetchReports = mapped
        this.fetchWarehouses = warehouses.map((w) => w.warehouse)
      } catch (error) {
        console.error(error.message)
        this.setSnackbar({
          value: true,
          message: error.message,
          type: 'error'
        })
      } finally {
        this.loading = false
      }
    },
    getDateFormat (date) {
      const month = this.$dayjs().month(date.month - 1).locale('th').format('MMMM')
      return `${month} ${date.year}`
    },
    async saveForm (dayoffForm) {
      try {
        this.loading = true

        const saveForm = dayoffForm.save
        const deleteForm = dayoffForm.delete

        for await (const formId of deleteForm) {
          await WorkDayService.deleteWorkDay(formId)
        }

        for await (const form of saveForm.warehouses) {
          form.month = saveForm.month
          form.userId = this.employee.userId

          if (form.id) {
            await WorkDayService.updateWorkDay(form)
          } else {
            await WorkDayService.createWorkDay(form)
          }
        }

        this.setSnackbar({
          value: true,
          message: 'Edit work day success.',
          type: 'success'
        })

        await this.initWorkDay()
      } catch (error) {
        console.error(error.message)
        this.setSnackbar({
          value: true,
          message: error.message,
          type: 'error'
        })
      } finally {
        this.loading = false
      }
    }
  }
}
</script>
