<template>
  <v-container
    class="pms-employee-detail-container"
    fluid>
    <h2>
      {{ isShowPMS ? 'Performance Report' : 'Employees Detail' }}
    </h2>
    <v-progress-circular
      v-if="loading"
      class="overlay-progress"
      :size="70"
      :width="7"
      color="black"
      indeterminate />

    <performance-report-individual
      v-else-if="isShowPMS"
      :employee="fetchEmployee"
      :reports="reports"
      :report-detail="reportDetail"
      @back="toggleShowPMS()" />
    <div v-else>
      <div class="pms-employee-detail-header">
        <div class="pms-employee-detail-name">
          <span>
            {{ `${fetchEmployee.firstName} ${fetchEmployee.lastName}` }}
          </span>
        </div>

        <edit-modal
          class="pms-employee-detail-edit"
          :employee="fetchEmployee"
          @save-form="saveForm($event)" />
      </div>

      <div class="detail-container">
        <v-row>
          <v-col>
            <span class="title">ชื่อ</span>
            <span class="textValue">{{ fetchEmployee.firstName }}</span>
          </v-col>
          <v-col>
            <span class="title">นามสกุล</span>
            <span class="textValue">{{ fetchEmployee.lastName }}</span>
          </v-col>
        </v-row>

        <v-row>
          <v-col>
            <span class="title">เบอร์โทร</span>
            <span class="textValue">{{ fetchEmployee.tel }}</span>
          </v-col>
          <v-col>
            <span class="title">อีเมล</span>
            <span class="textValue">{{ fetchEmployee.email }}</span>
          </v-col>
        </v-row>

        <v-row>
          <v-col>
            <span class="title">รายชื่อติดต่อฉุกเฉิน</span>
            <span class="textValue">
              {{ `${fetchEmployee.emergencyContact.name} (${fetchEmployee.emergencyContact.tel})` }}
            </span>
          </v-col>
          <v-col>
            <span class="title">ตำแหน่ง</span>
            <span
              class="textValue"
              style="text-transform: capitalize;">
              {{ mapRole(fetchEmployee.role) }}
            </span>
          </v-col>
        </v-row>

        <v-row>
          <v-col>
            <span class="title">วันที่เริ่มงาน</span>
            <span class="textValue">{{ dateFormat(fetchEmployee.startWorkDate) }} ({{ dateDiff }})</span>
          </v-col>
          <v-col>
            <span class="title">สถานะ</span>
            <span
              class="textValue"
              style="text-transform: capitalize;">
              {{ fetchEmployee.status }}
            </span>
          </v-col>
        </v-row>

        <v-row>
          <v-col>
            <span class="title">ยอดขายรวม</span>
            <span class="textValue">{{ !!sales ? sales : '-' }} บาท</span>
          </v-col>
        </v-row>
      </div>

      <div class="pms-employee-detail-tabs">
        <employee-tabs
          :employee="fetchEmployee"
          @show-pms="showPMS($event)" />
      </div>
    </div>
  </v-container>
</template>

<script>
import { mapActions } from 'vuex'
import EmployeeRoles from '@/assets/json/EmployeeRoles.json'
import EmployeeProvider from '@/resources/EmployeeProvider'
import EmployeeReportProvider from '@/resources/EmployeeReport'
import EmployeeTabs from '../components/EmployeeTabs.vue'
import EditModal from '../components/EditModal.vue'
import PerformanceReportIndividual from '../components/PerformanceReportIndividual.vue'

const EmployeeService = new EmployeeProvider()
const EmployeeReportService = new EmployeeReportProvider()

export default {
  components: {
    EmployeeTabs,
    EditModal,
    PerformanceReportIndividual
  },
  data () {
    return {
      loading: true,
      dateDiff: '',
      sales: '',
      fetchEmployee: null,
      reports: [],
      reportDetail: null,
      isShowPMS: false
    }
  },
  mounted () {
    this.getEmployeeById()
  },
  methods: {
    ...mapActions({
      setSnackbar: 'Components/setSnackbar'
    }),
    async getEmployeeById () {
      try {
        this.loading = true

        const { data } = await EmployeeService.getEmployeeByUserId(this.$route.params.userId)
        this.fetchEmployee = data

        this.initDate()
        this.initSales()
      } catch (error) {
        console.error('getEmployeeById', error)
        this.setSnackbar({
          value: true,
          message: error.message,
          type: 'error'
        })
      } finally {
        this.loading = false
      }
    },
    initDate () {
      const date = this.$dayjs(this.fetchEmployee.startWorkDate)
      const year = (+date.format('YYYY'))
      const month = (+date.format('M'))
      const day = (+date.format('D'))

      const now = this.$dayjs()
      const nowYear = (+now.format('YYYY'))
      const nowMonth = (+now.format('M'))
      const nowDay = (+now.format('D'))

      this.dateDiff = `${Math.abs(nowYear - year)} ปี ${Math.abs(nowMonth - month)} เดือน ${Math.abs(nowDay - day)} วัน`
    },
    async initSales () {
      try {
        this.loading = true

        const { data } = await EmployeeReportService.getEmployeeReportsByUserId(this.$route.params.userId)
        this.sales = this.numberFormat(data.sales) || 0
      } catch (error) {
        console.error('initSales', error.message)
        this.setSnackbar({
          value: true,
          message: error.message,
          type: 'error'
        })
      } finally {
        this.loading = false
      }
    },
    numberFormat (number) {
      const tmpNumber = number.toFixed(2)
      return Number(tmpNumber).toLocaleString('en')
    },
    dateFormat (date) {
      return this.$dayjs(date).format('YYYY-MM-DD')
    },
    async saveForm (employeeForm) {
      try {
        this.loading = true

        await EmployeeService.updateEmployee(employeeForm)

        this.setSnackbar({
          value: true,
          message: 'Update employee detail success.',
          type: 'success'
        })

        // const warehouses = [...this.fetchEmployee.warehouses]
        // data.warehouses = warehouses

        this.getEmployeeById()
      } catch (error) {
        console.error('saveForm', error.message)
        this.setSnackbar({
          value: true,
          message: error.message,
          type: 'error'
        })
      } finally {
        this.loading = false
      }
    },
    mapRole (role) {
      switch (role) {
        case EmployeeRoles.SALES_STAFF: return 'Sales Staff'
        case EmployeeRoles.ASSIST_STORE_MANAGER: return 'Assist Store Manager'
        case EmployeeRoles.STORE_MANAGER: return 'Store Manager'
        case EmployeeRoles.AREA_MANAGER: return 'Area Manager'
        default: return role
      }
    },
    showPMS (detailReports) {
      this.reports = detailReports.reports
      this.reportDetail = detailReports.detail
      this.isShowPMS = true
    },
    toggleShowPMS () {
      this.isShowPMS = false
    }
  }
}
</script>

<style scoped>
.pms-employee-detail-container {
  display: flex;
  flex-direction: column;
  background-color: #fff;
  border-radius: 5px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 2px 12px;
}
.pms-employee-detail-container > div > .detail-container {
  margin: 0 auto;
}
.pms-employee-detail-container > div > button {
  width: 20%;
  max-width: 100px;
  margin-left: 50%;
  transform: translateX(-50%);
}
.pms-employee-detail-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 90%;
  margin: 30px auto 40px auto;
  height: 36px;
}
.pms-employee-detail-header > .pms-employee-detail-name {
  background-color: #e9e9e9;
  border-radius: 4px;
  display: flex;
  align-items: center;

  padding: 4px 8px;
  width: calc(100% - 120px);
  height: 100%;
}
.pms-employee-detail-header > .pms-employee-detail-edit {
  width: 80px;
  align-self: center;
}
.pms-employee-detail-tabs {
  width: 80%;
  height: fit-content;
  margin: 10px auto;
}
.detail-container {
  width: 80%;
}
.title {
  display: inline-block;
  font-size: 0.95rem !important;
  font-weight: 600;
  min-width: 145px;
}
.textValue {
  font-size: 0.8rem !important;
}
.overlay-progress {
  margin-top: 50px;
  left: 50%;
  transform: translateX(-50%);
}
</style>
