var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.tableHeaders,"items":_vm.contents,"loading":_vm.loading,"hide-default-footer":""},scopedSlots:_vm._u([{key:"item.warehouse",fn:function(ref){
var item = ref.item;
return _vm._l((item.warehouse),function(warehouse,index){return _c('div',{key:index},[_vm._v(" "+_vm._s(warehouse)+" ")])})}},{key:"item.dayoff",fn:function(ref){
var item = ref.item;
return _vm._l((item.dayoff),function(dayoff,index){return _c('div',{key:index},[_vm._v(" "+_vm._s(dayoff)+" ")])})}},{key:"item.daywork",fn:function(ref){
var item = ref.item;
return _vm._l((item.daywork),function(daywork,index){return _c('div',{key:index},[_vm._v(" "+_vm._s(daywork)+" ")])})}},{key:"item.edit",fn:function(ref){
var item = ref.item;
return [_c('edit-day-off-modal',{attrs:{"reports":item,"fetch-warehouses":_vm.fetchWarehouses},on:{"save-form":function($event){return _vm.saveForm($event)}}})]}}],null,true)})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }