<template>
  <div>
    <v-card>
      <v-card-title>
        <add-complain-modal @save-form="saveForm($event)" />
      </v-card-title>
      <v-data-table
        :headers="tableHeaders"
        :items="contents"
        :items-per-page="10"
        :loading="loading"
        class="elevation-1">
        <template v-slot:[`item.date`]="{ item }">
          <tr>
            <td>{{ getDateFormat(item.date) }}</td>
          </tr>
        </template>
        <template v-slot:[`item.type`]="{ item }">
          <tr>
            <td> {{ checkType(item.type) }} </td>
          </tr>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import EmployeeComplainProvider from '@/resources/EmployeeComplainProvider'
import AddComplainModal from './AddComplainModal.vue'

const EmployeeComplainService = new EmployeeComplainProvider()

export default {
  components: { AddComplainModal },
  props: {
    employee: {
      type: Object,
      default: () => {}
    }
  },
  data () {
    return {
      tableHeaders: [
        {
          text: 'วันที่',
          sortable: true,
          value: 'date'
        },
        {
          text: 'ข้อความ',
          align: 'center',
          sortable: false,
          value: 'description'
        },
        {
          text: 'ประเภท',
          align: 'start',
          sortable: false,
          value: 'type'
        }
      ],
      fetchComplain: [],
      loading: false
    }
  },
  computed: {
    contents () {
      return this.fetchComplain
    }
  },
  created () {
    this.initComplain()
  },
  methods: {
    ...mapActions({
      setSnackbar: 'Components/setSnackbar'
    }),
    async initComplain () {
      try {
        this.loading = true

        const end = this.$dayjs()
        const start = end.subtract(5, 'month')
        const query = {
          start: start.toISOString(),
          end: end.toISOString(),
          employeeUserId: this.employee.userId
        }

        const { data: complains } = await EmployeeComplainService.getEmployeesComplain(query)

        this.fetchComplain = complains.results
      } catch (error) {
        console.error(error.message)
        this.setSnackbar({
          value: true,
          message: error.message,
          type: 'error'
        })
      } finally {
        this.loading = false
      }
    },
    getDateFormat (date) {
      return this.$dayjs(date).format('D / M / YY')
    },
    checkType (type) {
      return (type === 'positive' ? 'ชม' : 'คอมเพลน')
    },
    async saveForm (complainForm) {
      try {
        this.loading = true

        const tmpForm = complainForm
        tmpForm.employee = this.employee

        const { data } = await EmployeeComplainService.createEmployeeComplain(tmpForm)

        if (data) {
          this.setSnackbar({
            value: true,
            message: 'Create complain success.',
            type: 'success'
          })
          await this.initComplain()
        } else {
          const error = 'Create complain failed.'
          throw error
        }
      } catch (error) {
        console.error(error.message)
        this.setSnackbar({
          value: true,
          message: error.message,
          type: 'error'
        })
      } finally {
        this.loading = false
      }
    }
  }
}
</script>
