import { getAuthToken } from '@/assets/js/Authentication'
import HttpRequest from './HttpRequest'

class EmployeeReportProvider extends HttpRequest {
  getEmployeeReportsByUserId (id) {
    this.setHeader(getAuthToken())
    return this.get(`employee-report/${id}`)
  }

  getEmployeeReportsByMonth (id, query) {
    this.setHeader(getAuthToken())
    return this.get(`employee-report/${id}/by-months`, query)
  }
}

export default EmployeeReportProvider
