<template>
  <v-row justify="end">
    <v-dialog
      v-model="dialog"
      persistent
      max-width="600px">
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          class="mx-1 my-1"
          color="grey lighten-2"
          elevation="0"
          v-bind="attrs"
          v-on="on">
          <v-icon small>
            mdi-plus
          </v-icon>
          เพิ่ม
        </v-btn>
      </template>
      <v-card>
        <v-card-title>
          <span class="text-h5">เพิ่ม คำชม/คอมเพลน</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12">
                <v-menu
                  ref="datePicker"
                  v-model="datePicker"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  max-width="290px"
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="dateFormatted"
                      label="วันที่"
                      prepend-icon="mdi-calendar"
                      dense
                      outlined
                      hide-details
                      v-bind="attrs"
                      @blur="date = parseDate(dateFormatted)"
                      v-on="on" />
                  </template>
                  <v-date-picker
                    v-model="date"
                    no-title
                    @input="datePicker = false"
                  ></v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  v-model="complainForm.description"
                  label="ข้อความ"
                  dense
                  outlined
                  hide-details
                  required />
              </v-col>
              <v-col cols="12">
                ประเภท
                <v-radio-group
                  v-model="complainForm.type"
                  row>
                  <v-radio
                    label="ชม"
                    value="positive" />
                  <v-radio
                    label="คอมเพลน"
                    value="negative" />
                </v-radio-group>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue darken-1"
            text
            @click="closeForm()">
            ปิด
          </v-btn>
          <v-btn
            color="blue darken-1"
            text
            @click="saveForm()">
            บันทึก
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
  export default {
    data () {
      return {
        date: null,
        dateFormatted: null,
        datePicker: false,
        dialog: false,
        complainForm: {
          date: '',
          description: '',
          type: ''
        }
      }
    },
    watch: {
      date (val) {
        const diff = this.$dayjs().diff(val, 's')

        if (diff < 0) {
          this.date = this.$dayjs().format('YYYY-MM-DD')
          this.dateFormatted = this.formatDate(this.$dayjs().format('YYYY-MM-DD'))
        } else {
          this.dateFormatted = this.formatDate(val)
        }
      }
    },
    created () {
      this.initDate()
    },
    methods: {
      initDate () {
        this.date = this.$dayjs().format('YYYY-MM-DD')
        this.dateFormatted = this.$dayjs().format('YYYY-MM-DD')
      },
      formatDate (date) {
        if (!date) return null

        return this.$dayjs(date).format('MM/DD/YYYY')
      },
      parseDate (date) {
        if (!date) return null

        return this.$dayjs(date).format('YYYY-MM-DD')
      },
      saveForm () {
        this.complainForm.date = this.$dayjs(this.date).toISOString()
        this.$emit('save-form', this.complainForm)
        this.clear()
        this.dialog = false
      },
      closeForm () {
        this.clear()
        this.dialog = false
      },
      clear () {
        this.complainForm = {
          date: '',
          description: '',
          type: ''
        }

        this.initDate()
      }
    }
  }
</script>
