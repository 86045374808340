import { getAuthToken } from '@/assets/js/Authentication'
import HttpRequest from './HttpRequest'

class EmployeeComplainProvider extends HttpRequest {
  getEmployeesComplain (query) {
    this.setHeader(getAuthToken())
    return this.get('employee-complain', query)
  }

  getEmployeeComplainById (id) {
    this.setHeader(getAuthToken())
    return this.get(`employee-complain/${id}`)
  }

  createEmployeeComplain (employeeComplainForm) {
    this.setHeader(getAuthToken())
    return this.post('employee-complain', employeeComplainForm)
  }

  updateEmployee (employeeComplainForm) {
    this.setHeader(getAuthToken())
    return this.put(`employee-complain/${employeeComplainForm.id}`, employeeComplainForm)
  }
}

export default EmployeeComplainProvider
