import { getAuthToken } from '@/assets/js/Authentication'
import HttpRequest from './HttpRequest'

class WorkDayProvider extends HttpRequest {
  getWorkDays (query) {
    this.setHeader(getAuthToken())
    return this.get('work-day', query)
  }

  getWorkDayGroupByMonth (query) {
    this.setHeader(getAuthToken())
    return this.get('work-day/by-monthes', query)
  }

  getWorkDayById (id) {
    this.setHeader(getAuthToken())
    return this.get(`work-day/${id}`)
  }

  getWorkDayByUserId (id) {
    this.setHeader(getAuthToken())
    return this.get(`work-day/${id}/user-id`)
  }

  createWorkDay (workDayForm) {
    this.setHeader(getAuthToken())
    return this.post('work-day', workDayForm)
  }

  updateWorkDay (workDayForm) {
    this.setHeader(getAuthToken())
    return this.put(`work-day/${workDayForm.id}`, workDayForm)
  }

  deleteWorkDay (id) {
    this.setHeader(getAuthToken())
    return this.delete(`work-day/${id}`)
  }
}

export default WorkDayProvider
