<template>
  <div>
    <v-data-table
      :headers="tableHeaders"
      :items="contents"
      hide-default-footer
      :loading="loading"
      class="elevation-1">
      <template #[`item.month`]="{ item }">
        {{ getDateFormat(item.month) }}
      </template>
      <template #[`item.sales`]="{ item }">
        {{ numberFormat(item.sales) }}
      </template>
      <template #[`item.grossPms`]="{ item }">
        {{ numberFormat(item.grossPms) }}
      </template>
      <template #[`item.more`]="{ item }">
        <div class="d-flex justify-end align-center">
          <v-btn
            class="mx-1"
            color="grey lighten-2"
            elevation="0"
            small
            @click="showPMS(item)">
            รายละเอียด
          </v-btn>
        </div>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import EmployeeReportProvider from '@/resources/EmployeeReport'

const EmployeeReportService = new EmployeeReportProvider()

export default {
  props: {
    employee: {
      type: Object,
      default: () => {}
    }
  },
  data () {
    return {
      tableHeaders: [
        {
          text: 'เดือน',
          sortable: true,
          value: 'month'
        },
        {
          text: 'Sales',
          sortable: false,
          align: 'center',
          value: 'sales'
        },
        {
          text: 'Gross PMS',
          sortable: false,
          align: 'center',
          value: 'grossPms'
        },
        {
          text: '',
          sortable: false,
          value: 'more'
        }
      ],
      fetchReports: [],
      loading: false
    }
  },
  computed: {
    contents () {
      return this.fetchReports
    }
  },
  created () {
    this.initReports()
  },
  methods: {
    ...mapActions({
      setSnackbar: 'Components/setSnackbar'
    }),
    async initReports () {
      try {
        this.loading = true

        const end = this.$dayjs()
        const start = end.subtract(5, 'month')
        const query = {
          start: start.format('M-YYYY'),
          end: end.format('M-YYYY')
        }
        const { data: reports } = await EmployeeReportService.getEmployeeReportsByMonth(this.employee.userId, query)
        this.fetchReports = reports
      } catch (error) {
        console.error(error.message)
        this.setSnackbar({
          value: true,
          message: error.message,
          type: 'error'
        })
      } finally {
        this.loading = false
      }
    },
    getDateFormat (month) {
      const tmpMonth = this.$dayjs().month(month.month - 1).locale('th').format('MMMM')
      return `${tmpMonth} ${month.year}`
    },
    numberFormat (number) {
      if (!number) {
        return Number(0).toLocaleString('en')
      }
      const tmpNumber = number.toFixed(2)
      return Number(tmpNumber).toLocaleString('en')
    },
    showPMS (detail) {
      this.$emit('show-pms', { detail, reports: this.fetchReports })
    }
  }
}
</script>
